.root {
  height: 85vh;
  width: 100%;
  /* float: left; */
  display: flex;
  }

.block {
  height: 50px;
  border: ridge;
  margin: auto;
  /* margin-top: 50px; */
  width: 100%;
}



.DefaultMainPage {
  margin-right: auto;
  margin-left: auto;
  width: fit-content;

}

