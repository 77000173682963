
.questionBlock {
    height: 58%;
    /* border: ridge; */
    margin: auto;
    /* margin-top: 50px; */
    width: 100%;
    text-align: center;
  }
  
  .questionBlockTitle {
      /* padding-top: 10px; */
      /* padding-bottom: 10px; */
      /* border: ridge; */
      margin: auto;
      /* margin-top: 10px; */
      text-align: left;
      /* height: 50px; */
      padding-left: 50px;
      font-size: 22px;
      line-height: 28px;
      /* color: #21A052; */
      font-weight: 500;
      font-style: 'SemiBold';
  }
  
  .questionInputWrapper {
    height: 83%;
  }
  
  .inputsWrapper {
      padding-left: 42px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0px;
      width: min-content;
      margin-top: 20px;
  }
  
  .inputsWrapper2 {
      /* padding-top: 29px; */
      padding-left: 42px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0px;
      /* background-color: #dfe5e5; */
      /* max-width: 520px; */
      width: min-content;
      margin-top: 20px;
  }
  
  .fio {
    height: 48px;
    /* border: ridge; */
    margin: auto;
    /* margin-top: 50px; */
    width: 256px;
    display: inline-grid;
  }
  
  .email {
      height: 48px;
      /* border: ridge; */
      margin: auto;
      /* margin-top: 50px; */
      width: 256px;
      display: inline-grid;
      /* margin-left: 55px; */
  }
  
  .programm {
      height: 48px;
      /* border: ridge; */
      margin: auto;
      /* margin-top: 50px; */
      width: 557px;
      display: inline-grid;
  
  }
  
  .email_programmWrapper {
      padding-top: 29px;
      padding-left: 42px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0px;
      /* background-color: #dfe5e5; */
      /* max-width: 520px; */
      width: min-content;
  }
  
  .question {
      height: 100%;
      /* border: ridge; */
      margin: auto;
      /* margin-top: 50px; */
      width: 860px;
      display: inline-grid;
  }
  
  .questionWrapper {
      padding-top: 29px;
      padding-left: 42px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0px;
      /* background-color: #dfe5e5; */
      /* max-width: 520px; */
      width: min-content;
      height: 40%;
  }
  
  .questionBlockWrapper {
      width: fit-content;
      height: 100%;
      /* margin: auto; */
  }
  
  .sendbuttonWrapper {
      height: 48px;
      /* border: ridge; */
      margin: auto;
      margin-top: 10px;
      width: 100%;
      display: inline-grid;
  }
  
  .docsbuttonWrapper {
      height: 48px;
      /* border: ridge; */
      margin: auto;
      margin-top: 10px;
      width: 100%;
      display: inline-grid;
  }
  
  .docsbutton {
      height: 48px;
      /* border: ridge; */
      /* margin: auto; */
      margin-top: 10px;
      width: 256px;
      /* display: inline-grid; */
      margin-left: 42px;
    }
  
  
  .sendbutton {
      height: 48px;
      /* border: ridge; */
      /* margin: auto; */
      margin-top: 50px;
      width: 256px;
      /* display: inline-grid; */
      margin-left: 42px;
    }
  
  .resultWrapper {
      margin-left: 45px;
      margin-top: 40px;
  }
  
  .resultTitle {
      text-align: left;
      margin-bottom: 10px;
  }
  
  .selectTitle {
      margin-bottom: 5px;
      text-align: left;
      opacity: 0.5;
  }
  h2{
      text-align: left;
      opacity: 0.5;
      padding-left: 42px;
      margin-bottom: 0;
      margin-top: 65px;
  }
.results{
    margin-top: 50px;
    text-align: left;
    padding-left: 42px;
    font-size: 22px;
}
.results div{
    margin-top: 10px;
}