.root {
    display: flex;
}

.prev {
    list-style: none;
    margin-right: 21px;
    cursor: pointer;
}

.next {
    margin-left: 21px;
    list-style: none;
    cursor: pointer;
}

.page_item, .active {
    list-style: none;
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    margin: 0 24px;
    letter-spacing: 0.04em;

    color: #28323E;
}

.active {
    color: #FF7F00;
    font-weight: 600;
}

.page_item:hover {
    color: #FF7F00;
}

