.notification_wrapper {
  margin-right: 65px;
  width: 100%;
}

.notification_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  color: #28323e;
  margin-bottom: 40px;
}

.close_auth_modal {
  position: absolute;
  right: 32px;
  top: 25px;
  cursor: pointer;
}

.title_auth_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #292929;
}
.content_view_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.02em;

  color: #292929;
  margin-top: 91px;
  margin-bottom: 46px;
  box-sizing: border-box;
}

.btn_login:active {
  opacity: 0.7;
}

.btn_main,
.btn_main_2 {
  width: 100%;
  height: 48px;
  left: 48px;
  top: 296px;

  background: #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f9f9f9;
}

.btn_main_2 {
  border: 1px solid #ff7f00;
  border-radius: 5px;
  color: #ff7f00;
  background: none;
  margin-top: 24px;
}

.linkModal {
  margin-bottom: 20px;
  margin-top: 10px;
}
.linkModal a {
  color: #292929;
}
.linkModal a:hover,
.fileInput label:hover {
  color: #ff7f00;
}

.formNotif {
  margin-bottom: 20px;
  margin-top: 20px;
}

.textAreaFormAnswer {
  width: 90%;
  padding: 12px 20px;
  margin-bottom: 20px;
  resize: none;
}

.fileInput {
  margin-bottom: 20px;
  cursor: pointer;
}

.fileInput label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameFiles {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.02em;

  color: #28323e;
  margin-bottom: 10px;
}

.counNoRead {
  /* position: absolute; */
  /* z-index: 10; */
  top: -5px;
  left: 0px;
  font-size: 17px;
  background-color: #ff7f00;
  color: #f9f9f9;
  font-weight: bold;
  /* width: 25px; */
  text-align: center;
}

.btn_go_to_appeals {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.02em;
  border: none;
  background: none;
  color: #ff7f00;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 15px;
  display: block;
  text-align: center;
}
