

.Wrapper {
    /* height: 50px; */
    /* border: ridge; */
    /* margin: auto; */
    margin-top: 10px;
    width: 90%;
    text-align: left;
    /* padding-left: 50px; */
    display: inline-block;
    margin-left: 50px;
}

.block {
  height: 50px;
  border: ridge;
  margin: auto;
  /* margin-top: 50px; */
  width: 250px;
  display: inline-grid;
  margin-left: 50px;
}
