.root_header {
  width: 100%;
  height: 88px;
  display: flex;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  color: #292929;
}

.header_left {
  padding-top: 29px;
  padding-left: 72px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  max-width: 440px;
  width: 440px;
  min-width: 440px;
  padding-right: 31px;
  box-sizing: border-box;
}

.header_top_menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-right: 209px;
  margin-top: 31px;
  width: 100%;
  /* text-align: center; */
  margin-left: 40px;
}

.header_top_menu div {
  cursor: pointer;
  white-space: pre;
  margin: 0 10px;
}

.wrap_language {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.language {
  display: flex;
  align-items: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  cursor: pointer;
  color: #292929;
}

.language:hover {
  background-color: #29292915;
}

.language img {
  margin-left: 11px;
}

.header_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  color: #292929;
  cursor: pointer;
}

.header_right {
  display: flex;
  width: 100%;
  margin-right: 65px;
}

.search_n_user {
  display: flex;
}

.icon_profile {
  margin-left: 61px;
  margin-top: 24px;
}

.icon_profile:active {
  opacity: 0.7;
}

.icon_profile svg {
  cursor: pointer;
}

.input_search {
  margin-top: 28px;
}

.active_menu_header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  text-decoration-line: underline;

  color: #ff7f00;
  margin: 0 10px;
}

.close_auth_modal {
  position: absolute;
  right: 32px;
  top: 25px;
  cursor: pointer;
}

.title_auth_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #292929;
}

.inputs_login {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}

.input_login,
.input_password {
  margin-bottom: 24px !important;
}

.btn_forgot_password,
.btn_login_ecp {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;
  text-decoration-line: underline;

  color: rgba(41, 41, 41, 0.8);
  cursor: pointer;
}

.btn_forgot_password:active,
.btn_login_ecp:active,
.btn_login:active,
.btn_register:active,
.btn_cancel_modal_reg:active {
  opacity: 0.7;
}

.btn_login_ecp {
  margin-top: 20px;
  margin-bottom: 31px;
}

.btn_login,
.btn_register,
.btn_cancel_modal_reg {
  width: 100%;
  height: 48px;
  left: 48px;
  top: 296px;

  background: #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f9f9f9;
}

.btn_register,
.btn_cancel_modal_reg {
  border: 1px solid #ff7f00;
  border-radius: 5px;
  color: #ff7f00;
  background: none;
  margin-top: 24px;
}

.btn_cancel_modal_reg {
  margin-top: 0px;
  margin-right: 40px;
}

.ncaLayerError_modal,
.ncaLayerLoad_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  letter-spacing: 0.02em;

  color: #ff0000;
  margin-top: 10px;
}

.ncaLayerLoad_modal {
  color: black;
}

.modal_login {
  margin-top: 18px;
}

.validate_form,
.validate_form_reg,
.status_auth {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  letter-spacing: 0.02em;
  text-decoration-line: underline;
  margin-bottom: 37px;
  color: #ff0000;
}

.validate_form_reg {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: absolute !important;
  top: 64px;
}

.status_auth {
  color: #292929;
  text-align: center;
  margin-bottom: -18px;
  display: flex;
  text-decoration-line: none;

  justify-content: center;
}

.validate_form {
  display: flex;
  flex-direction: column;
}

.content_reg_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #292929;
  margin-top: 27px;
  overflow-y: scroll;
  max-height: 270px;
  height: 100%;
}

.buttons_group_reg {
  width: 100%;
  max-width: 472px;
  display: flex;
  margin: 0 auto;
  margin-top: 34px;
}

.license_check {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #292929;
  margin-top: 28px;
}

.license_check label {
  cursor: pointer;
}

.form_registration {
  padding-top: 31px;
}

.group_input_registration,
.group_input_select {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 27px;
}

.group_input_registration div,
.group_input_select div {
  box-sizing: border-box;
  width: 240px;
  height: 48px;
  position: relative;
  margin-bottom: 28px;
}

.group_input_select div {
  margin-bottom: 0;
}

.labelNinputReg label,
.label_select {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  top: -8px !important;
  color: #28323e;
}

.label_select {
  font-size: 18px !important;
  color: #28323e !important;
}

.input_iin_one:hover {
  border: 1px solid black !important;
}

.input_iin_one:focus {
  border-color: #1976d2 !important;
  border: 1px solid #1976d2;
}

.input_iin_one {
  box-sizing: border-box;
  width: 328px;
  height: 48px;
  left: 48px;
  top: 128px;

  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 17px 19px;
}

.content_success_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.02em;

  color: #292929;
  margin-top: 43px;
  margin-bottom: 46px;
}

.modalLoginOption {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modalLoginOption span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #ff7f00;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.activeRecovery {
  margin-top: 36px;
  margin-bottom: 44px;
}

.activeRecovery ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 27px;
}

.err_active_recovery {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  letter-spacing: 0.02em;

  color: #ff0000;
  margin-bottom: 24px;
}

.input_password_wrapper {
  position: relative;
  width: 100%;
}

.input_password {
  width: 100%;
}

.icon_view_eye {
  position: absolute;
  left: 90%;
  top: 15px;
  cursor: pointer;
}
