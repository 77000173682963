

.SendAppeals {
    height: 50px;
    /* border: ridge; */
    /* margin: auto; */
    margin-top: 10px;
    width: 250px;
    /* text-align: left; */
    /* padding-left: 50px; */
    /* display: inline-block; */
    /* margin-left: 470px; */
    /* border: ridge; */
    position: fixed;
    right: 150px;
    z-index: 1;
}

.close_auth_modal {
    position: absolute;
    right: 32px;
    top: 25px;
    cursor: pointer;
  }

.title_auth_modal {
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 20px;
/* identical to box height, or 100% */

letter-spacing: 0.02em;
margin-bottom: 35px;
color: #292929;
}