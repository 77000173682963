.newsWrapper {
  /* height: 50px; */
  margin: auto;
  /* margin-top: 50px; */
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.newsblock {
  height: 250px;
  border: ridge;
  margin: auto;
  /* margin-top: 50px; */
  width: 250px;
  display: inline-grid;
}

.allNews {
  /* height: 50px; */
  /* border: ridge; */
  margin: auto;
  /* margin-top: 50px; */
  width: initial;
  text-align: right;
  padding-right: 90px;
  cursor: pointer;
}

.newsItem {
  height: 216px;
  width: 256px;
  margin: 0 16px 32px;
  cursor: pointer;
  opacity: 0.9;
  box-shadow: 0px 0px 7.16084px 1.79021px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: relative;
}
.newsItem:hover {
  opacity: 1;
  transition: 0.3s;
}

.newsItemImg {
  width: 100%;
  height: 128px;
  margin-bottom: 10px;
}

.newsItemImg img {
  height: 100%;
  width: 100%;
}

.newsItemTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: start;
  padding: 0 16px;
  color: #28323e;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}

.newsItemFooter {
  position: absolute;
  bottom: 6px;
  right: 8.76px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 12.8px;
  line-height: 19px;

  color: #ff7f00;
}

.newsItemFooter svg {
  margin-left: 10px;
}
