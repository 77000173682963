.galleryPicture {
  height: 152px;
  /*width: calc(100% / 9 * 2.4);*/
  width: 256px;
  margin: 0 16px 32px;
  padding-left: 24px;
  cursor: pointer;
  opacity: 0.9;
}

.galleryPicture:hover {
  transform: scale(1.02);
  transition: 0.5s;
  opacity: 1;
}

.galleryWrapper {
  margin-top: 45px;
  margin-left: 8px;

  position: relative;
}

.gallery {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.btnLoadPhotos,
.btn {
  height: 48px;
  width: 206px;
  margin-left: 40px;
  margin-bottom: 40px;
  background: #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #f9f9f9;
}

.btn {
  width: 100%;
  margin-left: 0;
  margin-bottom: 0;
}

.btnTwo {
  border: 1px solid #ff7f00;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
  width: 100%;
  letter-spacing: 0.02em;
  height: 48px;
  color: #ff7f00;
  background: none;
  margin-top: 16px;
  cursor: pointer;
}

.btnLoadPhotos:active {
  opacity: 0.7;
}

.close_auth_modal {
  position: absolute;
  right: 32px;
  top: 25px;
  cursor: pointer;
}

.title_file_modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;
  margin-bottom: 28px;
  color: #292929;
}

.errorLoad {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  /* or 144% */

  letter-spacing: 0.02em;

  color: #ff0000;
  margin-top: 38px;
  margin-bottom: 16px;
}

.isReplace {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 32px;
  color: #28323e;
}

.galleryPagination {
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
}

.BtnDelImg {
  position: absolute;
  left: 262px;
  z-index: 10;
  cursor: pointer;
  width: 30px;
  height: 50px;
  background: inherit;
  mix-blend-mode: difference;
  font-size: 3em;
  text-align: center;
  filter: invert(1);
}

.BtnDelImg:hover {
  transform: scale(1.3);
  transition: 0.3s;
}

.wrapImg {
  position: relative;
}

.wrapModalConfirm {
  position: relative;
  width: 100%;
  height: 100%;
}

.modalConfirm {
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  width: 300px;
  height: 200px;
}

.modalConfirmMessage {
  font-family: var(--GothamProRegular);
  font-size: 19px;
  margin-top: 30px;
  text-align: center;
}
.modalConfirmButton {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
}
.modalConfirmButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 123px;
  height: 40px;
}

.btnReset {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  color: #ff7f00;
  width: 256px;
  height: 40px;

  border: 1px solid #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  background: none;
}

.btnEditNews {
  width: 214px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f0f0f0;
  border: none;
  background: #ff7f00;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 32px;
}
