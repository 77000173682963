.adminNewsRoot {
  margin-left: 40px;
}

.headerPublished {
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin-top: 40px;
}

.filter {
  margin-right: 33px;
  box-sizing: border-box;
}
.filter div {
  height: 40px;
}

.search input {
  width: 256px;
  height: 40px;
  box-sizing: border-box;

  border: 1px solid #28323e;
  border-radius: 5px;
  padding: 12px 20px;
  opacity: 0.5;
}

.search input:focus {
  opacity: 1;
}

.search {
  position: relative;
  margin-right: 32px;
}
.search svg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btnReset {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  color: #ff7f00;
  width: 256px;
  height: 40px;

  border: 1px solid #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  background: none;
}

.btnReset:active,
.btnSavePost:active,
.btnAddNews:active,
.btnDeleteNews:active,
.btnEditNews:active {
  opacity: 0.7;
}

.btnAddNews {
  width: 214px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f0f0f0;
  border: none;
  background: #ff7f00;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 32px;
}
.btnEditNews {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  margin-right: 32px;

  letter-spacing: 0.02em;

  color: #ff7f00;

  width: 200px;
  height: 40px;

  border: 1px solid #ff7f00;
  border-radius: 5px;
  background: none;
  cursor: pointer;
}
.btnSavePost {
  width: 176px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f9f9f9;
  margin-right: 42px;
  background: #ff7f00;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btnDeleteNews {
  width: 160px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  color: #ff0000;
  cursor: pointer;

  letter-spacing: 0.02em;
  border: 1px solid #ff0000;
  border-radius: 5px;
  background: none;
}

.interactions {
  margin-top: 32px;
  display: flex;
}

.table {
  margin-top: 28px;
}

.close_add_news_modal {
  position: absolute;
  right: 32px;
  top: 25px;
  cursor: pointer;
}
.title_add_news_modal {
  margin-bottom: 28px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #292929;
}

.labelinputAddNews {
  width: 100%;
}

.labelinputAddNews label,
.labelinputAddNewsText label {
  display: flex;
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  line-height: 14px;
  /* identical to box height, or 100% */
  top: -8px !important;
  color: #28323e;
}

.labelinputAddNewsText input {
  height: 216px;
  font-size: 14px;
  border: 1px solid #e4e5e6;
}

.labelinputAddNewsText textarea {
  border: 1px solid #e4e5e6;
  border-radius: 3px;
  padding: 16.5px 14px;
  width: 100%;
  box-sizing: border-box;
}

.labelinputAddNewsText textarea:focus {
  opacity: 1;
}

.validate_form_add_news {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  letter-spacing: 0.02em;
  text-decoration-line: underline;
  margin-bottom: 37px;
  color: #ff0000;
}

.wrapperFormAddNews {
  display: flex;
  width: 100%;
}

.dnd {
  /* margin-top: 34px; */
}

.groupBtnAddNews {
  display: flex;
  margin-top: 56px;
}

.errorLoad {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  /* or 144% */

  letter-spacing: 0.02em;

  color: #ff0000;
  margin-top: 38px;
  margin-bottom: 16px;
}

.wrapModalConfirm {
  position: relative;
  width: 100%;
  height: 100%;
}

.modalConfirm {
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  width: 300px;
  height: 200px;
}

.modalConfirmMessage {
  font-family: var(--GothamProRegular);
  font-size: 19px;
  margin-top: 30px;
  text-align: center;
}
.modalConfirmButton {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
}
.modalConfirmButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 123px;
  height: 40px;
}
