.rootPost {
  margin-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  width: 55vw;
}
.headerPost {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.headerDiv {
  width: 100%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #28323e;
  padding-right: 20px;
}

.headerDateCreate {
  position: relative;
  bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.headerImg {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;
  box-sizing: border-box;
}
.headerImg img {
  /* display: block;
  width: 100%;
  height: auto;
  max-width: 84.7%;
  border-radius: 6px 6px 0px 0px; */
  display: block;
  width: 100%;
  height: auto;
}

.headerTitle {
  width: 100%;
}
.bodyPost {
  margin-top: 40px;
  width: 100%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* or 150% */
  text-align: justify;
  color: #28323e;
}

.bodyPost p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  margin-top: 15px;
}

.bodyPost blockquote {
  margin: 0px;
}

.bodyPost em {
  display: block;
  margin-top: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 14px;
}

.bodyPost a {
  font-weight: 700;
  text-decoration: underline;
  color: #000000;
}
.bodyPost blockquote {
  padding-left: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: #575757;
  background: #f7c19ba1 url(Group.svg) no-repeat calc(100% - 33px) 50%/72px;
  padding: 25px 125px 25px 15px;
}

.bodyPost figure {
  margin-top: 15px !important;
}
