
.Wrapper {
    width: 100%;
    }

.title {
    height: 50px;
    /* border: ridge; */
    margin: auto;
    /* margin-top: 50px; */
    width: 100%;
    padding-left: 50px;
    color: darkgray;
}

.maintitle {
    height: 50px;
    margin: auto;
    width: 100%;
    padding-left: 50px;
    font-size: x-large;
}