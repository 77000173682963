.Wrapper {
  /* height: 50px; */
  /* border: ridge; */
  /* margin: auto; */
  margin-top: 10px;
  width: 90%;
  text-align: left;
  /* padding-left: 50px; */
  display: inline-block;
  margin-left: 50px;
}

.block {
  height: 50px;
  border: ridge;
  margin: auto;
  /* margin-top: 50px; */
  width: 250px;
  display: inline-grid;
  margin-left: 50px;
}

.notification_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  color: #28323e;
  margin-bottom: 40px;
}
