.wrapper{
    padding: 0 50px;
    width: 100%;
}
.filtersWrapper{
    display: flex;
    gap: 40px;
}
.filtersWrapper input{
    width: 20vw;
}
.switchMap{
    margin-top: 40px;
    display: flex;
    justify-content: right;
    gap: 40px;
    margin-bottom: 40px;
}
.switchMap span{
    cursor: pointer;
    font-weight: 600;
}
.active{
    color: #FF7F00;
}