.personal_area_wrapper {
  margin-left: 40px;
  margin-top: 35px;
  width: 567px;
}
.personal_area_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
  margin-bottom: 55px;
  color: #292929;
}

.personal_area_info {
  display: flex;
  flex-direction: column;
}

.personal_area_info div {
  display: flex;
  position: relative;
}

.personal_area_info div svg {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: -1;
}
.personal_area_info div svg:active,
.personal_area_name_org svg:active {
  opacity: 0.7;
}

.info_subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  color: #292929;
  width: 100%;
  min-width: 218px;
  max-width: 218px;
  margin-right: 38px;
  margin-bottom: 32px;
}

.personal_area_info input,
.personal_area_name_org input,
.info_subtitle_sub {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  background: none;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */
  padding: 0;
  height: 16px;
  letter-spacing: 0.02em;
  border: none;
  color: #292929;
  padding-bottom: 7px;
  width: 80%;
  outline: none;
  cursor: pointer;
}
.info_subtitle_sub {
  cursor: auto;
}

.personal_area_name_org div {
  width: 100%;
  min-width: 363px;
  max-width: 363px;
}
.personal_area_name_org input {
  width: 98%;
  background: none;
}

.personal_area_name_org {
  margin-top: 59px;
  position: relative;
}
.personal_area_name_org svg {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: -1;
}

.btn_edit {
  width: 100%;
  height: 48px;
  left: 48px;
  top: 296px;

  background: #ff7f00;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  color: #f9f9f9;
  margin-top: 82px;
}
.status_auth {
  color: #292929;
  text-align: center;
  margin-bottom: -40px;
  display: flex;
  text-decoration-line: none;

  justify-content: center;
}
